<template>
  <div>
    <NModal
      class="custom-card"
      v-model:show="modalShow"
      preset="card"
      style="width: 550px;"
      :title="modalTitle"
      :bordered="false"
      :close-on-esc="false"
      :mask-closable="false"
      @after-leave="handleAfterLeave"
    >
      <NForm
        ref="formRef"
        require-mark-placement="left"
        :rules="formRules"
        :model="formValue"
      >
        <NFormItem label="实训书名称" path="name">
          <NInput
            maxlength="50"
            placeholder="请输入实训书名称名称"
            show-count
            clearable
            v-model:value="formValue.name"
          />
        </NFormItem>
        <NFormItem label="实训书封面：" path="coverArr">
          <AddLocalFile
            ref="addLocalFileRef"
            triggerType="picPreview"
            class="pic-uploader"

            :maxNum="1"
            accept=".jpg,.jpeg,.png"
            :maxBytesSize="2 * 1024 * 1024"
            
            changeWithFileInfo
            @file-list-change="handleUpFileChange"

            :autoInitList="false"
          >
            <template #upbtn>
              <span>上传图片</span>
            </template>
          </AddLocalFile>
          <p style="margin-left: 10px; font-size: 12px; color: #999;">限上传 PNG、JPG、JPEG 格式图片 最佳设计宽高比 200:130，大小不超过 2M</p>
        </NFormItem>
        <NFormItem label="实训书简介">
          <NInput
            type="textarea"
            maxlength="500"
            show-count
            clearable
            v-model:value="formValue.intro"
          />
        </NFormItem>
      </NForm>
      <div class="storage-btns">
        <NSpace justify="end">
          <NButton size="small" @click="handleClickCancel">取消</NButton>
          <NButton size="small" type="primary" @click="handleClickConfirm">确定</NButton>
        </NSpace>
      </div>
    </NModal>

    <BeforeSubmitUpload
      ref="beforeSubmitUploadRef"

      @all-file-success="handleAllFileSuccess"
      @upload-error="handleUploadError"
    />
    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive, nextTick } from 'vue';
  import { NModal, NForm, NFormItem, NInput, NSpace, NButton, NInputNumber, useMessage } from 'naive-ui';

  import PageLoading from "@/components/PageLoading/index.vue";
  import AddLocalFile from '@/components/AddLocalFile/AddLocalFile.vue';
  import BeforeSubmitUpload from '@/components/BeforeSubmitUpload/BeforeSubmitUpload.vue';

  import { resStatusEnum } from "@/enumerators/http.js";
  import { generateEditAddLocalFileItem } from '@/components/AddLocalFile/generate-data.js';

  import { PostTaskCreate, GetTaskDetail, PostTaskUpdate } from "@/api/task.js";

  const emit = defineEmits(['list-update']);

  const message = useMessage();
  const { SUCCESS } = resStatusEnum;
  const loading = ref(false);

  let taskID;
  let coverChanged = false;
  const modalShow = ref(false);
  const modalTitle = ref('');
  const formRef = ref(null);
  const addLocalFileRef = ref(null);
  const beforeSubmitUploadRef = ref(null);
  function generateDefaultFormValue() {
    return {
      name: '',
      coverArr: [],
      intro: ''
    };
  }
  const formValue = reactive(generateDefaultFormValue());
  const formRules = {
    name: {
      required: true,
      message: '请输入实训书名称',
      trigger: 'blur'
    },
    coverArr: {
      key: 'cover',
      required: true,
      type: 'array',
      message: '请上传封面图',
      trigger: 'change'
    }
  };
  let uploadedPicObj = {};

  function handleUpFileChange({ fileList }) {
    coverChanged = true;
    formValue.coverArr = fileList;

    formRef.value.validate(
      () => {},
      rule => {
        return rule.key === 'cover';
      }
    );
  }
  function fileTempUpload({ ext, fileType, fileUrl, name, size, videoId }) {
    return new Promise((resolve, reject) => {
      const reqData = {
        'Task[upload]': [
          {
            file_url: fileUrl,
            file_name: name,
            file_size: size,
            extension: ext,
            file_type: fileType,
            video_id: videoId
          }
        ]
      }
      PostTaskCreate(reqData).then(({ code }) => {
        if (code === SUCCESS) {
          resolve();
        } else {
          reject();
        }
      }).catch(e => {
        reject();
      }).finally(() => {
        
      });
    });
  }
  function handleAllFileSuccess(filesSet) {
    try {
      Object.assign(uploadedPicObj, filesSet[0][0]);
      console.log(uploadedPicObj)
      loading.value = true;
      fileTempUpload(uploadedPicObj).then(() => {
        submitFn();
      }).catch(() => {
        loading.value = false;
      });
    } catch (e) {
      console.log(e);
    }
  }
  function handleUploadError() {
    console.log('有文件上传失败，返回');
    message.error('文件上传失败，请稍后重试');
  }

  function handleAfterLeave() {
    Object.assign(formValue, generateDefaultFormValue());
    uploadedPicObj = {};
  }
  function handleClickCancel() {
    modalShow.value = false;
  }
  function submitFn() {
    let reqFn = null;
    let successMsg = '';
    if (taskID) {
      reqFn = PostTaskUpdate;
      successMsg = '编辑成功';
    } else {
      reqFn = PostTaskCreate;
      successMsg = '新增成功';
    }
    const reqData = {
      'Task[title]': formValue.name,
      'Task[image_url]': uploadedPicObj.fileUrl,
      'Task[introduce]': formValue.intro
    };
    if (taskID) {
      Object.assign(reqData, {
        'Task[id]': taskID,
        'Task[type]': 1
      });
    }
    loading.value = true;
    reqFn(reqData).then(({ code }) => {
      if (code === SUCCESS) {
        message.success(successMsg);
        handleClickCancel();
        emit('list-update');
      }
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      loading.value = false;
    });
  }
  function handleClickConfirm() {
    formRef.value.validate(errors => {
      if (!errors) {
        if (coverChanged) {
          beforeSubmitUploadRef.value.startUpload([formValue.coverArr]);
        } else {
          submitFn();
        }
      }
    });
  }

  defineExpose({
    openEditModal(id) {
      modalTitle.value = '编辑任务书信息';
      coverChanged = false;
      taskID = id;
      loading.value = true;
      GetTaskDetail({
        'Task[id]': taskID
      }).then(({ code, data }) => {
        if (code === SUCCESS) {
          const { title, image_url, introduce } = data;
          Object.assign(formValue, {
            name: title,
            coverArr: [],
            intro: introduce || ''
          });
          if (image_url) {
            const uploadedFileData = generateEditAddLocalFileItem({ id: 999, file_url: image_url });
            formValue.coverArr.push(uploadedFileData);
            uploadedPicObj = uploadedFileData;
            nextTick(() => {
              nextTick(() => {
                addLocalFileRef.value.initListFn([{
                  id: 999,
                  name: '',
                  status: 'finished',
                  url: image_url
                }]);
              });
            });
          }
          modalShow.value = true;
        }
      }).finally(() => {
        loading.value = false;
      });
    }
  });
</script>

<style lang="less" scoped>
  .pic-uploader {
    margin-bottom: 0 !important;

    :deep(.n-upload),
    :deep(.n-upload-trigger.n-upload-trigger--image-card),
    :deep(.n-upload-file.n-upload-file--image-card-type) {
      width: 200px;
      height: 130px;
    }
  }
</style>