<template>
  <div id="account-management">
    <NForm inline label-placement="left">
      <NFormItem label="搜索标题：">
        <NInput
          maxlength="50"
          placeholder="请输入任务书名称或ID"
          show-count
          clearable
          v-model:value="formValue.title"
          @update:value="ChangeTitle"
        />
      </NFormItem>
      <NFormItem>
        <NSpace>
          <NButton type="primary" @click="handleSearch">搜索</NButton>
          <NButton @click="handleClear">清除</NButton>
        </NSpace>
      </NFormItem>
    </NForm>

    <div class="create-bar">
      <n-button type="primary" class="account-btn" @click="handleClickCreate" v-if="ownUrlPermission('task/create')">新增实训任务书</n-button>
    </div>
    <!-- <div class="account-null-btn"></div> -->
    <NTabs
      type="line"
      @before-leave="handleTabBeforeLeave"
      @update:value="handleTabChange"
    >
    <n-tab-pane
      v-for="item in resourceTypes"
        :key="item.type"
        :name="item.type"
        :tab="item.name"
      >
      </n-tab-pane>
    </NTabs>
     <NAlert
      v-show="BookLength.length > 0"
          style="margin-bottom: 10px;"
          type="info"
        >
        已选择 <span :style="{ color: '#33b6ff' }">{{ BookLength.length }}</span> 项数据
      </NAlert>
    <NButton type="info" ghost @click="DistributionInstitution(1)" style="margin-bottom:10px" 
    v-if="ownUrlPermission('task/distribute')">批量分发</NButton>
    <n-data-table
      remote
      ref="table"
      :single-line="false"
      :bottom-bordered="true"
      :columns="columns"
      :data="Datas"
      :row-key="(row) => row.id"
      :loading="false"
      :pagination="pagination"
      @update:page-size="handlePageSizeChange"
      @update:page="handlePageChange"
      :default-checked-row-keys="DefaultValue"
      @update:checked-row-keys="handleCheck"
    />
    
    <CreateModal
      ref="createModalRef"
      @list-update="handleSearch"
    />
    <!-- 分发组件 -->
    <Distribute
    ref="distributeRef"
    distribute-title="实训分发"
    :TrainingBook="TrainingBook"
    :label-type="resourceEnum.TEACHING_RESOURCE_TYPE"
    @distribute-success="handlePageChange(1)"
    />
    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
import { reactive, defineEmits, defineExpose, ref, h, computed } from "vue";
import { NSpace, NButton, NTime, NPopconfirm, useMessage } from "naive-ui";

import CreateModal from './CreateModal.vue';

import accountType from "@/enumerators/account-type";
import { resStatusEnum } from "@/enumerators/http.js";
import { GetTaskIndex, PostTaskState, PostTaskDelete, copyTask } from "@/api/task.js";
import { toRaw } from '@vue/reactivity'
import { useRouter } from 'vue-router';
import PageLoading from "@/components/PageLoading/index.vue";
import { resourceEnum } from '@/enumerators/resource-types-map.js';
import Distribute from '@/components/TrainingDistribution/Distribute.vue';
import ownUrlPermission from '@/utils/own-url-permission.js';

const resourceTypes = ref([
  {type:2,name:'全部'},
  {type:1,name:'使用中'},
  {type:0,name:'已停用'}
]);

// 切换监控
function handleTabBeforeLeave() {
  return !loading.value;
}

// 点击切换
function handleTabChange(name) {
  let index = Number(name);
  if(index==2){
    formValue.type_status = ''; // 类型赋值
  }else{
    formValue.type_status = index; // 类型赋值
  }
  handlePageChange(1);
}

const DefaultValue = ref([])    // 选择默认值
const TrainingBook = ref([])
const BookLength = ref([])

const handleCheck = (idsArr)=> {
  DefaultValue.value = idsArr
  BookLength.value = idsArr
  let data = []
  TrainingBook.value.forEach(item3=>{
    data.push(item3.id)
  })
  Datas.value.forEach(item=>{
    idsArr.forEach(item1=>{
      if(item.id == item1){
        let isdata = data.indexOf(item1)
        if(isdata<0){
          TrainingBook.value.push(toRaw(item))
        }
      }
    })
  })
}

const message = useMessage();
const router = useRouter();
// 总数据
const Datas = ref([])
const columns =  [
    { type: 'selection' },
    {
      title: "实训书ID",
      key: "id",
      width: 85
    },
    {
      title: "实训书名称",
      key: "title",
    },
    {
      title: "创建人",
      key: "admin_username"
    },
    {
    title: "更新日期",
    width: 170,
    render: (row) =>
      h(NTime, {
        time: (Number(row.update_time) || 0) * 1000,
      }),
    },
    /*
    {
      title: "用于授课数",
      key:"lecture_num",
    },
    */
    /*
    {
      title: '分发院校数',
      width: 100,
      render: ({ distribute_school }) => {
        const num = Number(distribute_school) || 0;
        return h(
          'span',
          num > 0 ? null : { style: 'color: #ccc;' },
          num > 0 ? num : '未分发'
        )
      }
    },
    */
    {
      title: '使用情况',
      width: 90,
      render: ({ change }) => {
        change = Number(change);
        let propObj = null;
        let txt = '已使用';
        if (change === 0) {
          propObj = { style: 'color: #ccc;' };
          txt = '未使用'
        }
        return h('span', propObj, txt);
      }
    },
    {
      title: '状态',
      width: 85,
      render: row => h(
        'span',
        { class: Number(row.type_status) === 0 ? 'error-color' : 'success-color' },
        getResourceName(row.type_status)
      )
    },
    {
      title: '操作',
      width: 165,
      key: "type_status",
      render: row => h(
        NSpace,
        null,
        {
          default: () => Operation(row)
        }
      )
    }
  ];

// 数据请求 / 分页所需--------------
const { SUCCESS } = resStatusEnum;
const loading = ref(false);

// 数据初始化
function updateList(data){
  loading.value = true;
  // 请求列表数据
  GetTaskIndex(data)
    .then((res) => {
      if (res.code === SUCCESS) {
        loading.value = false;
        Datas.value = res.data.list
        updateItemCount(res.data.total)
        // console.log("请求列表数据3:",Datas)
      }
    })
    .catch((reject) => {});
};
updateList(toRaw(formValue));

// 状态
 function getResourceName(type_status) {
  return accountType[type_status] || '';
}

// 监控标题输入
 function ChangeTitle(title) {
  pagination.page = 1;
  formValue.page = 1;
}

//操作
 function Operation(row) {
  let tempArr = [];
  let arr1 = h(NButton, { text: true, type: 'info', onClick: () => ViewDetails(row) }, { default: () => '查看' })
  let arr2 = h(NButton, { text: true, type: 'info', onClick: () => DistributionInstitution(0,row) }, { default: () => '分发院校' })
  let arr3 = h(NButton, { text: true, type: 'success', onClick: () => DisableEnable(row) }, { default: () => '启用' })
  let arr4 = h(NButton, { text: true, type: 'info', onClick: () => toAddLabel(row) }, { default: () => '实训书建设' })
  let arr5 = h(NButton, { text: true, type: 'error', onClick: () => toDelLabel(row) }, { default: () => '删除' })
  let arr6 = h(NButton, { text: true, type: 'error', onClick: () => DisableEnable(row) }, { default: () => '停用' })
  const editBriefInfoBtn = h(
    NButton,
    { text: true, type: 'info', onClick: () => editBriefInfo(row) },
    { default: () => '编辑信息' }
  );
  const copyBtn = h(
    NPopconfirm,
    { onPositiveClick: () => confirmCopy(row) },
    {
      default: () => h('div', null, [h('span', null, '你确定要复制此内容吗？'), h('br'), h('span', null, '内容涉及MP3、MP4文件需重新上传。')]),
      trigger: () => h(NButton, { text: true, type: 'primary' }, { default: () => '复制' })
    }
  );
  // 查看
  if (ownUrlPermission('task/detail')) {
    tempArr.push(arr1);
  }
  // 分发
  if (ownUrlPermission('task/distribute') && Number(row.type_status) === 1) {
    tempArr.push(arr2);
  }
  if (Number(row.change) === 0 || Number(row.type_status) === 0) {
    // 编辑
    if (ownUrlPermission('task/update')) {
      tempArr.push(arr4);
    }
  }
  if(Number(row.type_status) === 0){
    // 状态改变
    if (ownUrlPermission('task/state')) {
      tempArr.push(arr3);
    }
  }else{
    // 状态改变
    if (ownUrlPermission('task/state')) {
      tempArr.push(arr6);
    }
  }
  if (ownUrlPermission('task/update')) {
    tempArr.push(editBriefInfoBtn);
  }
  if (ownUrlPermission('task/copy')) {
    tempArr.push(copyBtn);
  }
  if (Number(row.type_status) === 0) {
    // 删除
    if (ownUrlPermission('task/delete')) {
      tempArr.push(arr5);
    }
  }
  return tempArr;
}

const addLabelRef = ref(null);
// 编辑
function toAddLabel(row) {
  let id = row.id
  router.push(`/task/index/addedit/?id=${id}`);
}

function confirmCopy({ id, title }) {
  loading.value = true;
  copyTask({
    'Task[id]': id
  }).then(({ code }) => {
    if (code === SUCCESS) {
      message.success(`“${title}”复制成功`);
      updateList(toRaw(formValue))
    } else {
      loading.value = false;
    }
  }).catch(() => {
    loading.value = false;
  });
}

const createModalRef = ref(null);
function handleClickCreate() {
  router.push('addedit');
}
function editBriefInfo({ id }) {
  createModalRef.value.openEditModal(id);
}

 // 删除
function toDelLabel(row) {
  const dialog = window.$dialog;
  let data = {
    "Task[ids][0]":row.id
  }
  dialog.warning({
    title: '删除确认',
    content: `确定删除实训书:“${row.title}”？`,
    positiveText: '确定',
    negativeText: '取消',
    onPositiveClick: () => {
      loading.value = true;
      PostTaskDelete(data).then((res) => {
        if (res.code === SUCCESS) {
          loading.value = false;
          message.info(res.msg);
          // 成功后 调用接口刷新页面
          updateList(toRaw(formValue))
          // 删除后 清除页面checked状态
          DefaultValue.value = []
          TrainingBook.value = []
          BookLength.value = []
        }
      })
      .catch((reject) => {})
    },
    onNegativeClick: () => {}
  });

  // 账号删除接口
    
}

  // 停用启用
  function DisableEnable(row){
    loading.value = true;
    var formDatas = new FormData()
    let data = {}
    data["Task[id]"] = row.id
    data["Task[type_status]"] = row.type_status == '0'?1 : 0
    PostTaskState(data).then((res) => {
      if (res.code === SUCCESS) {
        loading.value = false;
        message.info(res.msg);
        // 成功后 调用接口刷新页面
        updateList(toRaw(formValue))
      }
    })
    .catch((reject) => {});
  }

// 翻页 搜索参数集
var ParameterSet = {}

// 搜索
function handleSearch () {
  handlePageChange(1);
}

// 查看详情
function ViewDetails(row) {  
  let id = row.id
  window.open(`/full-page/task-preview?id=${id}`);
}

// 分发院校
function DistributionInstitution(is,row) { 

  let data = [] 
  // 分发
  if(is==0){
    // 如果没有选择批量分发 则赋值 TrainingBook
    if(TrainingBook.value.length==0){
      TrainingBook.value.push(row)
    }
    data.push(row.id)
    distributeRef.value.openModal();
  }
  // 批量分发
  else{
    if(TrainingBook.value.length==0){
      message.info("多选后方可点击");
    }else{
      data = DefaultValue.value
      distributeRef.value.openModal();
    }
  }
}

const pagination = reactive({
  page: 1,
  itemCount: 0,
  pageSize: 10,
  pageSizes: [10, 20, 30],
  showSizePicker: true,
});
const updateItemCount = (count) => {
  pagination.itemCount = Number(count) || 0;

};
const handlePageSizeChange = (pageSize) => {
  pagination.pageSize = pageSize;
  formValue.page_size = pageSize;
  handlePageChange(1);
};
const handlePageChange = (page) => {
  pagination.page = page;
  formValue.page = page;
  ParameterSet = toRaw(formValue)
  updateList(ParameterSet);
};


// 分页结束-------------------

const getDefaultFormValue = () => ({
  title: "",
  type_status: '',
  page: 1,
  page_size: 10,
});
const formValue = reactive(getDefaultFormValue());
const getSearchParams = () => {
  const params = {};
  Object.keys(formValue).forEach((key) => {
    const value = formValue[key];
    value && (params[key] = value);
  });
  return params;
};

const emit = defineEmits(["list-update"]);


const handleClear = () => {
  formValue.title = '';
  handleSearch();
};

// 分发
const distributeRef = ref(null);

const toDistribute = (row) => {
  // localStorage.setItem("schoolId",row.id);
  // localStorage.setItem("schoolName",row.school_name);
  distributeRef.value.openModal();
};

defineExpose({
  getSearchParams,
});

</script>

<style lang="less" scoped>
#account-management {
  height: 100%;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
}
.account-btn {
  float: right;
}
.account-null-btn {
  height: 50px;
  width: 120px;
}
.n-dialog__title{
  font-weight: 700 !important;
}

.create-bar {
  position: relative;

  :deep(.n-button) {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
}
</style>